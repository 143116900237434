import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../../components/v2/Layout';
import EmoriFormRoom from '../../../components/EmoriFormRoom';

const useStyles = makeStyles(theme => ({
  root: {},
}));

export default () => {
  const classes = useStyles();

  return (
    // loading in layout <EmoriFormRoom />
    <Layout bg='bg4' title='สร้างห้อง'>
      <EmoriFormRoom />
    </Layout>
  );
};
